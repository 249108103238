@import './mixins';

// color
$primary-color: #253A47;
$secondary-color: #F2F2F2;

$error-color: #FF0000;
$success-color: #00FF00;

$btn-primary-color: #1F647A;

$background-color: #F3F3F3;
$white: #FFFFFF;
$color-cinnabar: #28c3dc;


// font size
$font-xs: 12px;
$font-sm: 14px;
$font-base: 16px;
$font-lg: 18px;
$font-xl: 20px;
$font-xxl: 22px;
$font-h4: 24px;
$font-h3: 28px;
$font-h2: 32px;
$font-h1: 40px;


// margin and padding
$margin-xs: 10px;
$margin-sm: 15px;
$margin-base: 20px;
$margin-lg: 25px;
$margin-xl: 30px;
$margin-xxl: 35px;
$margin-xxxl: 40px;

// padding and padding
$padding-xs: 10px;
$padding-sm: 15px;
$padding-base: 20px;
$padding-lg: 25px;
$padding-xl: 30px;
$padding-xxl: 35px;
$padding-xxxl: 40px;

// border radius
$border-radius-xs: 5px;
$border-radius-sm: 10px;
$border-radius-base: 15px;


// other
$form-input-height: 64px;

$font-weight-bold: 700;
$font-weight-regular: 400;
$font-weight-light: 300;
$font-weight-extra-bold: 800;
$font-family: 'Open Sans', sans-serif !important;

// for mobile change variable sizes
@include mobile {
    $font-xxs: 10px;
    $font-xs: 11px;
    $font-sm: 12px;
    $font-base: 14px;
    $font-lg: 16px;
    $font-xl: 18px;
    $font-xxl: 20px;
    $font-h4: 22px;
    $font-h3: 26px;
    $font-h2: 30px;
    $font-h1: 34px;
}