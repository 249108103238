@import "./styles/mixins";
@import "./styles/variables.scss";
@import "./styles/shared.scss";
@import "./styles/typography.scss";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  background-color: #f3f3f3 !important;
}

html {
  --mat-stepper-header-selected-state-icon-background-color: #1f647a;
  --mat-stepper-header-done-state-icon-background-color: #1f647a;
  --mat-stepper-header-edit-state-icon-background-color: #1f647a;
  --mat-stepper-header-icon-background-color: #1f647a;
}