@import "./variables.scss";

@mixin font-style($size, $weight: null, $styles: null) {
    font-family: $font-family;
    font-size: $size;
    font-weight: $weight;

    @if $styles !=null {
        @each $property, $value in $styles {
            #{$property}: $value;
        }
    }
}

$font-sizes: (
    'xs': $font-xs,
    'sm': $font-sm,
    'base': $font-base,
    'lg': $font-lg,
    'xl': $font-xl,
    'h1': $font-h1,
    'h2': $font-h2,
);

$font-weights: (
    'regular': $font-weight-regular,
    'bold': $font-weight-bold,
    'extra-bold': $font-weight-extra-bold,
);

/** helper classes for font styles */

/** 
    *font sizes: .font-sm, .font-base, .font-lg, .font-xl, .font-h1, .font-h2
**/
@each $name, $size in $font-sizes {
    .font-#{$name} {
        @include font-style($size);
    }
}

/** 
    *font weight: .font-regular, .font-bold, .font-extra-bold 
**/
@each $name, $weight in $font-weights {
    .font-#{$name} {
        @include font-style(null, $weight);
    }
}

.font-italic {
    font-style: italic;
}

/** 
    *font weight and font size: 
    .font-regular-sm, .font-bold-sm, .font-extra-bold-sm
    .font-regular-base, .font-bold-base, .font-extra-bold-base
    .font-regular-lg, .font-bold-lg, .font-extra-bold-lg
    .font-regular-xl, .font-bold-xl, .font-extra-bold-xl
    .font-regular-h1, .font-bold-h1, .font-extra-bold-h1
    .font-regular-h2, .font-bold-h2, .font-extra-bold-h2
**/
@each $size-name, $size in $font-sizes {
    @each $weight-name, $weight in $font-weights {
        .font-#{$weight-name}-#{$size-name} {
            @include font-style($size, $weight, (word-spacing: 0));
        }
    }
}