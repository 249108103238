@use './../../node_modules/ngx-spinner/animations/ball-pulse-sync.css';

@import './variables.scss';
@import 'ngx-toastr/toastr';

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.relative {
    position: relative;
}


.heading {
    font-family: $font-family;
    font-size: $font-h1;
    font-weight: $font-weight-extra-bold;
    word-spacing: 0;

}

.customButton {
    background: $secondary-color 0% 0% no-repeat padding-box;
    border-radius: 0px 5px 5px 0px;
    opacity: 1;
    text-decoration-color: white;
}

.popover {
    --bs-popover-max-width: 100% !important;
}

.grecaptcha-badge {
    visibility: hidden;
}

.headerTextsm {
    letter-spacing: 0;
    line-height: 2.5rem;
    font-size: 0.75rem;
    text-align: left;
    color: $primary-color;
}

//No Margin - nm
.nm {
    margin: 0% !important;
    padding: 0% !important;
}

.nm-r {
    padding-right: 0% !important;
}

.nm-l {
    padding-left: 0% !important;
}

.card-nb {
    --bs-card-border-color: $background-color !important;
}

.viewOnlyMobile {
    display: none !important;
}

.viewOnlyDesktop {
    display: inline-flex;
}

.defaultIcon {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
}

// button classes
.btn {
    text-transform: uppercase;
}

.btn-primary {
    background-color: $btn-primary-color;
    border: 1px solid $secondary-color;
}

.btn-secondary {
    background-color: $white;
    border: 1px solid $btn-primary-color;
    color: $btn-primary-color;
}

.btn-base {
    padding: $padding-sm $padding-base;
}


.smallButton {
    background: $btn-primary-color;
    border: 1.5px solid $secondary-color;
    border-radius: 0.5rem;
}


.smallEditButton {
    width: 4rem;
    height: 1.8rem;
    background: white 0% 0% no-repeat padding-box;
    border: 1.5px solid $secondary-color;
    border-radius: 0.3rem;
}

.fontclass {
    font-weight: $font-weight-bold;
    font-size: 11px;
}

.bigfontclass {
    font-weight: $font-weight-bold;
    font-size: 15px;
}

.normalclass {
    font-weight: $font-weight-regular;
    font-size: 10px;
}

.smallfontclass {
    font-weight: normal;
    font-size: 11px;
}


.cardSubText {
    font-size: 10px;
    color: $secondary-color;
}

.selectButton {
    background: $secondary-color 0% 0% no-repeat padding-box;
    border-radius: 5px;
    width: 5rem; //6rem
    height: 2rem; //3rem
    opacity: 1;
}

.selectButtonText {
    font-weight: $font-weight-regular;
    font-size: 75%; //14px
    color: white;
}

.customCard {
    height: 70vh;
    overflow-y: auto;
    width: auto;
    background-color: $background-color;
}

@include mobile {

    button {
        position: relative;

        .spinner-border-sm {
            position: absolute !important;
            left: 40% !important;
            top: 35% !important;
        }

    }

    .headerTextsm {
        letter-spacing: 0;
        line-height: 0.75rem;
        font-size: 0.75rem;
        text-align: left;
        color: $primary-color;
    }

    .viewOnlyMobile {
        display: inline !important;
    }

    .viewOnlyDesktop {
        display: none !important;
    }

    // OTP for mobile
    ::ng-deep ng-otp-input {
        .ng-otp-input-wrapper {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .otp-input {
                flex: 1;
                height: 40px;
                font-size: $font-xl;
            }
        }
    }
}

// helpful classes

.items-center {
    align-items: center;
}

.gap-1 {
    gap: 4px;
}

.gap-2 {
    gap: 8px;
}

.gap-3 {
    gap: 12px;
}

.ml-1 {
    margin-left: 4px !important;
}

.ml-2 {
    margin-left: 8px !important;
}

.marker-top-right {
    top: 35px !important;
    right: 0px !important;
}

.marker-top-left {
    top: 35px !important;
    left: 0px !important;
}

.marker-bottom-right {
    bottom: 35px !important;
    right: 0px !important;
}

.marker-bottom-left {
    bottom: 35px !important;
    left: 0px !important;
}

.in-active {
    opacity: 0.6;
    cursor: not-allowed
}


.toast-message-error {
    background-color: #f7d6d6;
    background-image: none;
    padding-left: 15px;
    
    .toast-close-button {
        color: #6f1919;
    }
}

.toast-message-error.ngx-toastr {
    padding-left: 15px;
}

.toast-message-error-text {
    color: #6f1919
}