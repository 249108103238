// Define a mixin for mobile screens
@mixin mobile {
    @media only screen and (max-width: 675px) {
        @content;
    }
}

// Define a mixin for small desktop screens
@mixin tablet {
    @media only screen and (min-width: 676px) and (max-width: 1024px) {
        @content;
    }
}

// Define a mixin for large desktop screens
@mixin desktop {
    @media only screen and (min-width: 1025px) {
        @content;
    }
}